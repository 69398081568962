<template v-if="props">
  <v-simple-table
    v-if="props.results"
    dense
    :class="props.color"
    dark
    class="rounded-0"
  >
    <tbody>
      <tr>
        <td>MSS</td>
        <td class="text-center" v-text="props.results.mid_sem_score"></td>

        <td>ESS</td>
        <td class="text-center" v-text="props.results.end_of_sem_score"></td>

        <td>Remarks</td>
        <td class="text-center" v-text="props.results.remarks"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
  export default {
    props: ["results", "color"],
    setup(props) {
      return {
        props,
      };
    },
  };
</script>

<style>
</style>