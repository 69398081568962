<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ACTIVITY </span> LOGS
    </PageHeader>

    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="getStudentScript"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
              :rules="[(v) => !!v || 'Index number is required...']"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="loadStudent">
                    <span class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    Searching
                    <strong>Student...</strong>
                  </v-list-item-title>

                  <v-list-item-title v-else>
                    No
                    <strong>Student Found...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="logs.length > 0">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="logs"
          class="elevation-1"
          item-key="id"
          hide-default-footer
        >
          <template #item.updated_at="{ value }">
            {{ `${moment(value).format("MMMM Do YYYY, h:mm:ss a")}` }}
          </template>
          <template #item.log_name="{ value }">
            <span class="text-capitalize">{{ value }} </span>
          </template>
          <template #item.event="{ value }">
            <span class="text-capitalize">{{ value }} </span>
          </template>
          <template #item.properties="{ value }">
            <ResultTable :results="value.old" color="success" />
            <ResultTable v-if="value" :results="value.attributes" color="red" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    provide,
    reactive,
    toRefs,
  } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import ResultTable from "@/components/Faculty/ResultTable";

  export default defineComponent({
    components: { PageHeader, ResultTable },
    setup() {
      const vm = getCurrentInstance();
      const { getActiviyLog, getStudentCampus, signOut } = useActions([
        "getActiviyLog",
        "getStudentCampus",
        "signOut",
      ]);
      const { getters_log, getters_student_campus, user } = useGetters([
        "getters_log",
        "getters_student_campus",
        "user",
      ]);

      const searchStudentHeader = reactive({
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        overlay: false,
      });

      const {
        index_number,
        searchStudent,
        studentcampusBasket,
        loadStudent,
        overlay,
      } = toRefs(searchStudentHeader);

      const logTable = reactive({
        headers: [
          { text: "DATE", value: "updated_at" },
          { text: "DONE BY", value: "causer.title_name" },
          {
            text: "COURSE",
            value:
              "subject.header_campus_course_session.course.coursecode_coursename",
          },
          { text: "RESULTS", value: "properties" },
        ],
        color: "",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        logs: [],
      });

      const { color, msgHeader, msgBody, msgIcon, logs } = toRefs(logTable);

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          const infoBasket = {
            role: user.value.role,
            campus: user.value.campus,
          };
          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            });
        }
      });

      const getStudentScript = () => {
        overlay.value = true;
        getActiviyLog(index_number.value)
          .then(() => (logs.value = getters_log.value))
          .catch((e) => {
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;

              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 403:
                msgBody.value = e.response.data.messae;
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            overlay.value = false;
          });
      };

      provide("color", color);

      return {
        ...toRefs(logTable),
        ...toRefs(searchStudentHeader),
        debouncesearchStudent,
        getStudentScript,
      };
    },
  });
</script>

